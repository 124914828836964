.header-bar {
  width: 100%;
  margin: 0px 10px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dde0e4;
  background-image: url("../../assets/Images/header.jpg");
  margin-bottom: 20px;
}

.header-name-bar {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}

.header-name {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin: 10px 0px 0px 0px;
  color: white;
}

.header-sinhala-name {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  margin: 10px 0px 00px 0px;
  color: white;
}

.header-name-uni {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin: 10px 0px 20px 0px;
  color: white;
}

.header-uni-logo {
  object-fit: contain;
  max-width: 120px;
  width: 6%;
  padding: 20px;
  cursor: pointer;
}

@media (min-width: 900px) {
}

@media (max-width: 768px) {
  .header-name {
    font-size: 16px;
  }
  .header-sinhala-name {
    font-size: 20px;
  }
  .header-name-uni {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .header-name {
    font-size: 16px;
  }
  .header-sinhala-name {
    font-size: 18px;
  }
  .header-name-uni {
    font-size: 14px;
  }
  .header-uni-logo {
    width: 10%;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .header-name {
    font-size: 16px;
  }
  .header-sinhala-name {
    font-size: 20px;
  }
  .header-name-uni {
    font-size: 14px;
  }
  .header-uni-logo {
    width: 15%;
    padding: 10px;
  }
}

@media (max-width: 320px) {
  .header-name {
    width: 90%;
    font-size: 14px;
  }
  .header-sinhala-name {
    width: 90%;
    font-size: 18px;
  }
  .header-name-uni {
    width: 90%;
    font-size: 14px;
  }
  .header-uni-logo {
    width: 15%;
    padding: 10px;
  }
}

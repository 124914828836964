.preface {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdfdfd;
  min-height: 100vh;
}

.preface-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 70%;
  background: #fafbfc;
  padding-bottom: 100px;
  border-radius: 8px;
}

.preface-text-container {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.preface-body-text {
  line-height: 25px;
  margin-top: 10px;
  text-align: justify;
}

.writers-content {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.writer-single-content {
  display: flex;
  flex-direction: column;
}

.writer-bold {
  font-weight: bold;
}

.writer-semibold {
  font-weight: 500;
}

@media (min-width: 900px) {
}

@media (max-width: 768px) {
  .preface-content {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .preface-content {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .preface-content {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .preface-content {
    width: 100%;
  }
}

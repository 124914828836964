.editorial {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdfdfd;
  min-height: 100vh;
}

.editorial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 70%;
  background: #fafbfc;
  padding-bottom: 50px;
  border-radius: 8px;
}

.editorial-board {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.board-name {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
}

.editorial-icon {
  margin-right: 10px;
}

@media (min-width: 900px) {
}

@media (max-width: 768px) {
  .editorial-content {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .editorial-content {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .editorial-content {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .editorial-content {
    width: 100%;
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  width: inherit;
  background-image: url("../../assets/Images/pageBG.jpg");
  background-size: cover;
  background-position: bottom; 
  border-top: 2px solid black;
  margin-top: 100px;
}

.footer-item-container {
  padding: 60px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.copyright-text {
  color: black;
  font-weight: 500;
  text-align: center;
  line-height: 25px;
  margin-left: 100px;
  margin-right: 100px;
  font-weight: 600;
}

.poweredby-text {
  color: black;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
  font-weight: 600;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 20px 0px;
}

.uni-logo {
  width: 8%;
  max-width: 100px;
  object-fit: contain;
  cursor: pointer;
}

.cdepd-logo {
  width: 20%;
  max-width: 300px;
  margin-left: 20px;
  object-fit: contain;
  cursor: pointer;
}

.link-color {
  color: #a93e3c;
  background-color: transparent;
  text-decoration: none;
  font-weight: 900;
}

.version-text {
  color: black;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
  font-weight: 600;
  align-self: flex-end;
}

@media (max-width: 600px) {
  .uni-logo {
    width: 10%;
  }

  .cdepd-logo {
    width: 30%;
  }

  .footer-item-container {
    padding: 5px;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .copyright-text {
    margin-left: 60px;
    margin-right: 60px;
  }
}

@media (max-width: 480px) {
  .uni-logo {
    width: 20%;
  }

  .cdepd-logo {
    width: 40%;
  }

  .footer-item-container {
    padding: 5px;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .copyright-text {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 320px) {
  .uni-logo {
    width: 20%;
  }

  .cdepd-logo {
    width: 30%;
  }

  .footer-item-container {
    padding: 5px;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .copyright-text {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.disclaimer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdfdfd;
  min-height: 100vh;
}

.disclaimer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 70%;
  background: #fafbfc;
  padding-bottom: 100px;
  border-radius: 8px;
}

.disclaimer-text-container {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.disclaimer-body-text {
  line-height: 25px;
  margin-top: 10px;
  text-align: justify;
}

@media (min-width: 900px) {
}

@media (max-width: 768px) {
  .disclaimer-content {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .disclaimer-content {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .disclaimer-content {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .disclaimer-content {
    width: 100%;
  }
}

.body-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px;
  align-items: center;
  justify-content: center;
}

.empty-image {
  width: 50%;
  object-fit: contain;
}

.empty_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty_text {
  color: #757575;
  text-align: center;
}

@media (min-width: 900px) {
}

@media (max-width: 768px) {
  .empty-image {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .empty-image {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .empty-image {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .empty-image {
    width: 100%;
  }
}

.selectable-chip-text {
  margin-bottom: 2px;
  font-family: "Abhaya Libre", serif;
  font-size: 24px;
  font-weight: 600;
  color: #a93e3c !important;
}

.selectable-chip-text-selected {
  margin-bottom: 2px;
  font-family: "Abhaya Libre", serif;
  font-size: 24px;
  font-weight: 600;
}

.selectable-chip-text-roboto {
  margin-bottom: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #a93e3c !important;
}

.selectable-chip {
  margin: 10px 20px 10px 20px !important;
  border: 1px solid #a93e3c !important;
}

.not-selected {
  background-color: transparent !important;
}

.all-label {
  background-color: transparent !important;
}

.accordian-summary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 10000;
  background: #faf8f3 !important;
}

.accordian-details {
  border-top: 1px dashed #acabac;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* background: #FAF8F3 !important; */
}

.accordian-details::before {
  background-image: url("../../assets/Images/filter.png");
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
}

.accordian-root {
  margin-top: 30px;
  width: 95%;
}

.accordian-title {
  font-weight: 600 !important;
  align-self: center;
}

.search-button {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.search-input {
  width: 100%;
}

@media (max-width: 320px) {
  .selectable-chip-text {
    font-size: 20px;
  }
  
  .selectable-chip-text-selected {
    font-size: 20px;
  }
  
  .selectable-chip-text-roboto {
    font-size: 14px;
  }
  
  .selectable-chip {
    margin: 4px 5px 2px 5px !important;
  }

  .accordian-title {
    font-size: 14px !important;
  }

  .accordian-summary {
    flex: 1 !important;
    flex-wrap: nowrap;
  }
}
.top-nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  width: inherit;
  background: #a93e3c;
}

.top-nav-items {
  padding: 10px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
}

.top-nav-text {
  color: white;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .top-nav-text {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .top-nav-text {
    font-size: 12px;
  }
  .top-nav-items {
    padding-right: 20px;
  }
}

@media (max-width: 480px) {
  .top-nav-text {
    font-size: 12px;
  }
  .top-nav-items {
    padding-right: 20px;
  }
}

@media (max-width: 320px) {
  .top-nav-text {
    font-size: 12px;
  }
  .top-nav-items {
    padding-right: 20px;
  }
}

.single-card {
  width: 250px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  background: #FAF8F3 !important;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .single-card {
    width: 40%;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .single-card {
    width: 100%;
    height: 120px;
  }
}

@media (max-width: 320px) {
  .single-card {
    width: 100%;
    height: 120px;
  }
}


.card-title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.card-subtitle {
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: #444444;
  text-transform: capitalize;
}

.card-button {
  padding-top: 100px;
}
.sample {
  margin-top: 30px;
}

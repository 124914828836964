.meaning {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdfdfd;
  min-height: 100vh;
}

.meaning-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 70%;
  background: #ffffff;
  padding-bottom: 100px;
  border-radius: 8px;
}

.meaning-sinhala-heading {
  margin: 20px 0px 10px 0px;
}

.meaning-english-heading {
  display: inline;
  color: #424242;
  margin: 0px 0px 10px 0px;
  font-style: italic;
}

.meaning-author {
  display: flex;
  align-self: flex-end;
  margin-right: 40px;
  font-style: italic;
}

.meaning-content-para-text {
  margin: 40px;
  white-space: pre-wrap;
  line-height: 24px;
}

.meaning-resources {
  margin: 40px 0px 20px 40px;
}

.meaning-references-item {
  margin-top: 10px;
  line-height: 24px;
}

.meanings-figure-image {
  width: auto;
}

.meaning-full-length {
  width: 100%;
}

@media (min-width: 900px) {
}

@media (max-width: 768px) {
  .meaning-content {
    width: 100%;
  }
  .meanings-figure-image {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .meaning-content {
    width: 100%;
  }
  .meanings-figure-image {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .meaning-content {
    width: 100%;
  }

  .meaning-content-para-text {
    margin: 20px;
    white-space: pre-wrap;
  }

  .meaning-resources {
    margin: 20px 0px 20px 20px;
  }

  .meanings-figure-image {
    width: 90%;
  }
}

@media (max-width: 320px) {
  .meaning-content {
    width: 100%;
  }

  .meaning-content-para-text {
    margin: 20px;
    white-space: pre-wrap;
  }

  .meaning-resources {
    margin: 20px 0px 20px 20px;
  }

  .meanings-figure-image {
    width: 90%;
  }
}

.container-bg {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../assets/Images/pageBG.jpg") !important;
}

.container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 70%;
  background: transparent;
  z-index: 10;
}

.top-img {
  object-fit: contain;
  width: inherit;
  height: auto;
  margin-bottom: 20px;
  transform: rotate(180deg);
}

@media (min-width: 1024px) {
  .content {
    width: 90% !important;
  }
}

@media (max-width: 768px) {
  .content {
    width: 90% !important;
  }
}

@media (max-width: 600px) {
  .content {
    width: 90% !important;
  }
}

@media (max-width: 480px) {
  .content {
    width: 100% !important;
  }
}

@media (max-width: 320px) {
  .content {
    width: 100% !important;
  }
}
